/// <reference types="vite-plugin-pwa/client" />

import '@etchteam/recycling-locator';
// eslint-disable-next-line import/no-unresolved
import { registerSW } from 'virtual:pwa-register';

const MARKER_KEY = import.meta.env.VITE_MARKER_KEY;

function setupMarker() {
  window.markerConfig = {
    project: MARKER_KEY,
    source: 'snippet',
  };

  /* eslint-disable */
  !function(e,r,a){if(!e.__Marker){e.__Marker={};var t=[],n={__cs:t};["show","hide","isVisible","capture","cancelCapture","unload","reload","isExtensionInstalled","setReporter","setCustomData","on","off"].forEach(function(e){n[e]=function(){var r=Array.prototype.slice.call(arguments);r.unshift(e),t.push(r)}}),e.Marker=n;var s=r.createElement("script");s.async=1,s.src="https://edge.marker.io/latest/shim.js";var i=r.getElementsByTagName("script")[0];i.parentNode.insertBefore(s,i)}}(window,document);
  /* eslint-enable */
}

if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
  registerSW({ immediate: true });
}

if (MARKER_KEY) {
  setupMarker();
}
